const allCommentList=[
  {
    name:'Priya', 
    time:'September 15, 2023, 02:45', 
    content:"I've been using gb WhatsApp for a while now, and it's been great.",
  },
  {
    name:'Rajesh Patel', 
    time:'September 22, 2023, 06:20', 
    content:"gb WhatsApp provides a good set of features. It's a convenient messaging app overall.",
  },
  {
    name:'Siti Wahyuni', 
    time:'September 25, 2023, 10:55', 
    content:"The latest update of gb whatsapp improved the performance and stability. It's working smoothly.",
  },
  {
    name:'Siska Halim', 
    time:'September 30, 2023, 04:10', 
    content:'I appreciate the privacy options in gbwhatsapp. I feel more secure with my conversations.',
  },
  {
    name:'Putri Sari',
    time:'October 4, 2023, 11:45',
    content:'I want to download gb whatsapp app.',
  },
  {
    name:'Dewi Lestari',
    time:'October 8, 2023, 4:20',
    content:'I want to know how should I update my gb whatsapp.',
  },
  {
    name:'Aashi Gupta',
    time:'October 8, 2023, 1:10',
    content:"If I hide status, can people really not see if I'm online?",
  },
  {
    name:'Rajiv Sharma',
    time:'October 10, 2023, 5:30',
    content:'I love this app.',
  },
  {
    name:'Ashok Verma',
    time:'October 11, 2023, 11:15',
    content:'Good.',
  },
  {
    name:'Saira Khan',
    time:'October 15, 2023, 9:45',
    content:'I want to share this app with my friends.',
  },
  {
    name:'Saira Khan', 
    time:'October 17, 2023, 9:45', 
    content:'This app safe ? I want to use it.',
  },
  {
    name:'Rani Indriani', 
    time:'October 20, 2023, 1:25', 
    content:'How to download this app.',
  },
  {
    name:'Rahul', 
    time:'October 25, 2023, 09:50', 
    content:'gb whatsapp has a few minor bugs occasionally.',
  },
  {
    name:'Neha Sharma', 
    time:'October 27, 2023, 01:15', 
    content:"I'm satisfied with gb whatsapp.",
  },
  {
    name:'Deepak Verma', 
    time:'October 28, 2023, 08:40', 
    content:"gb whatsapp has a clean and user-friendly interface. It's easy to navigate and find what I need.",
  },
  {
    name:'Adi Nugroho', 
    time:'November 1, 2023, 03:25', 
    content:"I've recommended GB WhatsApp to my friends.",
  },
  {
    name:'Dewi Kusuma', 
    time:'November 2, 2023, 11:05', 
    content:'Gbwhatsapp occasionally drains more battery than expected. Hoping for better optimization in the future updates.',
  },
  {
    name:'Rajesh', 
    time:'November 3, 2023, 03:50', 
    content:"Gbwhatsapp's chat backup feature is a lifesaver.",
  },
  {
    name:'Priya Kapoor', 
    time:'November 3, 2023, 12:15', 
    content:'I appreciate the customization options in gb whatsapp. It allows me to personalize my chats.',
  },
  {
    name:'Siti Rahayu',
    time:'November 5, 2023, 3:45',
    content:'I like the customization that gb whatsApp offers, being able to adjust the layout and colors of the chat interface myself.',
  },
  {
    name:'Mason',
    time:'November 9, 2023, 8:15',
    content:'When will GBWhatsApp be updated to add new features?',
  },
  {
    name:'Sophia',
    time:'November 15, 2023, 9:00',
    content:'Gb whatsapp is very good app.',
  },
  {
    name:'Jaya Singhania',
    time:'November 21, 2023, 8:05',
    content:'I want to know how to use gb whatsapp channel.',
  },
  {
    name:'Aryan Desai',
    time:'November 24, 2023, 6:30',
    content:'My friend told me that I can download safe gb whatsapp here.',
  },
  {
    name:'Priya Mehra',
    time:'November 26, 2023, 2:50',
    content:'Good app gb whatsapp.',
  },
  {
    name:'Adi Santoso', 
    time:'November 26, 2023, 4:00', 
    content:'This app safe ? I want to use it.',
  },
  {
    name:'Aryan', 
    time:'November 28, 2023, 05:35', 
    content:"Gb WhatsApp is my go-to messaging app. It's reliable, and I haven't faced any major issues.",
  },
   {
    name:'Rahmat Suryanto', 
    time:'November 30, 2023, 11:15', 
    content:'The dark mode feature in GB WhatsApp is fantastic.',
  },
  {
    name:'Sanjay', 
    time:'November 30, 2023, 03:55', 
    content:"Gb WhatsApp's group chat functionality is great for coordinating with my friends.",
  },
   {
    name:'Maya', 
    time:'December 1, 2023, 09:30', 
    content:"Gb WhatsApp occasionally lags when handling a large number of chats, but it's still usable.",
  },
  {
    name:'Aditya', 
    time:'December 3, 2023, 03:30', 
    content:"Gb WhatsApp is my go-to messaging app. It's reliable and easy to use.",
  },
  {
    name:'Ravi', 
    time:'December 4, 2023, 08:20', 
    content:"It's been a smooth experience overall.",
  },
  {
    name:'Faisal', 
    time:'December 6, 2023, 01:45', 
    content:'Gb WhatsApp offers a great set of features.',
  },
  {
    name:'Nita', 
    time:'December 7, 2023, 07:10', 
    content:'I appreciate the privacy options in GB WhatsApp. It allows me to control who sees my profile.',
  },
  {
    name:'Rahul', 
    time:'December 7, 2023, 11:55', 
    content:"GB WhatsApp occasionally has minor glitches, but they're quickly resolved in updates.",
  },
  {
    name:'Siti Wahyuni', 
    time:'December 8, 2023, 04:40', 
    content:'Gb WhatsApp has a user-friendly interface, making it easy to navigate and find what I need.',
  },
  {
    name:'Vinod', 
    time:'December 13, 2023, 10:15', 
    content:"I've recommended gb whatsapp to my friends.",
  },
   {
    name:'Anjali', 
    time:'December 14, 2023, 03:50', 
    content:'The voice and video call quality in gbwhatsapp are impressive.',
  },
  {
    name:'Endang Susilo', 
    time:'December 16, 2023, 09:35', 
    content:"Gb WhatsApp's customization options allow me to personalize the app to my liking. I enjoy it.",
  },
  {
    name:'Vivek', 
    time:'December 16, 2023, 01:10', 
    content:'GB WhatsApp offers useful features, but it could use some performance optimizations for older devices.',
  },
  {
    name:'Rina Sari', 
    time:'December 20, 2023, 04:45', 
    content:'The voice and video call quality in gb whatsapp is impressive. Clear sound and smooth video experience.',
  },
  {
    name:'Akbar', 
    time:'December 21, 2023, 10:25', 
    content:"Gb WhatsApp's message scheduling feature is handy for sending reminders at the right time. I find it useful.",
  },
  {
    name:'Indah Purnomo', 
    time:'December 21, 2023, 03:50', 
    content:"Gbwhatsapp's privacy settings give me control over who can see my profile.",
  },
   {
    name:'Harsha', 
    time:'December 22, 2023, 08:15', 
    content:"gb WhatsApp's theming options allow me to personalize the app's appearance.",
  },
  {
    name:'Rina', 
    time:'December 22, 2023, 12:40', 
    content:"GB WhatsApp's file sharing capabilities make it easy to exchange documents and media with friends.",
  },
  {
    name:'Prakash', 
    time:'December 25, 2023, 03:20', 
    content:'A reliable messaging app.',
  },
  {
    name:'Rika Fitriani', 
    time:'December 27, 2023, 10:00', 
    content:"I like GB WhatsApp's anti-revoke feature quite useful at times.",
  },
  {
    name:'Ahmad', 
    time:'December 30, 2023, 02:25', 
    content:'The font customization options in gbwhatsapp are a nice touch.',
  },
  {
    name:'Priya', 
    time:'January 2, 2024, 02:20', 
    content:"Gb WhatsApp occasionally lags when handling multiple chats, but it's still my preferred app.",
  },
  {
    name:'Kavita Saxena', 
    time:'January 5, 2024, 08:05', 
    content:"Gbwhatsapp's group chat is great.",
  },
  {
    name:'Kiran', 
    time:'January 9, 2024, 12:50', 
    content:'A reliable messaging app.',
  },
  {
    name:'Sita', 
    time:'January 13, 2024, 05:25', 
    content:"GB WhatsApp's file sharing feature simplifies sharing documents and media with my contacts.",
  },
  {
    name:'Naina Kapoor', 
    time:'January 17, 2024, 11:10', 
    content:"I find gb whatsapp's interface more intuitive compared to other messaging apps.",
  },
  {
    name:'Meera', 
    time:'January 17, 2024, 11:05', 
    content:'GB WhatsApp has a great feature set.',
  },
   {
    name:'Rini Widjaja', 
    time:'January 19, 2024, 04:30', 
    content:'I enjoy using it.',
  },
  {
    name:'Arjun', 
    time:'January 20, 2024, 09:50', 
    content:"I appreciate the ability to lock individual chats in gb whatsapp for added privacy. It's a useful feature.",
  },
  {
    name:'Ananya', 
    time:'January 20, 2024, 01:15', 
    content:'The developers care about improving the user experience.',
  },
   {
    name:'Imran', 
    time:'January 23, 2024, 08:40', 
    content:'I find it easy to use GB WhatsApp.',
  },
  {
    name:'Kiran', 
    time:'January 27, 2024, 01:05', 
    content:'I love the ability to pin important chats in GB WhatsApp. It keeps my priority conversations at the top.',
  },
  {
    name:'Sunil Shah', 
    time:'February 1, 2024, 10:35', 
    content:"GB WhatsApp is my favorite messaging app. It's reliable and has a good set of features.",
  },
  {
    name:'Rohit Mehta', 
    time:'February 4, 2024, 03:55', 
    content:'The seamless transition from regular WhatsApp to GB WhatsApp is impressive. No data loss during the switch.',
  },
  {
    name:'Priya', 
    time:'February 5, 2024, 11:20', 
    content:"Gb whatsapp's message formatting options add a touch of creativity to my conversations. I enjoy using them.",
  },
  {
    name:'Aryan', 
    time:'February 6, 2024, 04:45', 
    content:"It's generally reliable.",
  },
  {
    name:'Manoj Chatterjee', 
    time:'February 6, 2024, 03:55', 
    content:'I enjoy using it.',
  },
  {
    name:'Dhruv', 
    time:'February 10, 2024, 09:30', 
    content:"I appreciate gb whatsapp's regular updates.",
  },
   {
    name:'Maya', 
    time:'February 11, 2024, 02:05', 
    content:'Gb WhatsApp is a good app.',
  },
  {
    name:'Sunita Patel', 
    time:'February 15, 2024, 07:40', 
    content:"I like the ability to customize GB WhatsApp's theme.",
  },
  {
    name:'Budi Santoso', 
    time:'February 19, 2024, 01:25', 
    content:"GBWhatsApp occasionally uses more battery, but it's a small trade-off for the features it offers.",
  },
  {
    name:'Shalini Bhatia', 
    time:'February 23, 2024, 06:00', 
    content:'Gb WhatsApp is my favorite app.',
  },
  {
    name:'Raj', 
    time:'February 28, 2024, 10:35', 
    content:"Gb WhatsApp's chat backup feature good.",
  },
   {
    name:'Laila', 
    time:'March 3, 2024, 03:10', 
    content:'I feel safe using it.',
  },
  {
    name:'Puja Krishnan', 
    time:'March 5, 2024, 08:45', 
    content:"Gb WhatsApp's message scheduling feature is handy for sending reminders and birthday wishes on time.",
  },
  {
    name:'Anita', 
    time:'March 5, 2024, 01:20', 
    content:'Hide online status gives me more privacy.',
  },
  {
    name:'Amit', 
    time:'March 10, 2024, 05:55', 
    content:"Gb whatsapp, It's impressive.",
  },
  {
    name:'Radha', 
    time:'March 11, 2024, 10:30 ', 
    content:"I find gb whatsapp's fonts and text formatting options delightful. It adds character to my chats.",
  },
  {
    name:'Aditya', 
    time:'March 15, 2024, 03:05', 
    content:'Gb occasionally takes longer to load.',
  },
  {
    name:'Anjali Joshi', 
    time:'March 20, 2024, 07:40', 
    content:'The video call quality in gb whatsapp is impressive.',
  },
  {
    name:'Vivek', 
    time:'March 26, 2024, 12:15', 
    content:'Anti-revoke feature is useful. I can view deleted messages.',
  },
  {
    name:'Vikram Reddy', 
    time:'March 30, 2024, 04:50', 
    content:'I enjoy to use it.',
  },
  {
    name:'Rajesh', 
    time:'April 2, 2024, 09:25', 
    content:'good.',
  },
  {
    name:'Ravi Malhotra', 
    time:'April 8, 2024, 02:00', 
    content:"Gb WhatsApp's message formatting options add a touch of creativity to my conversations. It's fun.",
  },
  {
    name:'Pooja Desai', 
    time:'April 13, 2024, 06:35', 
    content:'I like to use gbwhatsapp.',
  },
  {
    name:'Logan',
    time:'April 15, 2023, 6:00',
    content:'I chose gbwhatsapp among many versions.',
  },
  {
    name:'Joko Widodo',
    time:'April 20, 2023, 2:30',
    content:'gb whatsapp and gbwhatsapp pro have the same functions and are very easy to use.',
  },
  {
    name:'Arjun Patel',
    time:'April 24, 2023, 7:55',
    content:"I can download other people's statuses. That's great.",
  },
  {
    name:'Budi Setiawan',
    time:'April 25, 2023, 10:30',
    content:'I have been using gbwhatsApp for a while! It feels pretty good.',
  },
  {
    name:'Budi Santoso', 
    time:'April 27, 2023, 09:30', 
    content:'gbwhatsapp is a reliable messaging app. It gets the job done efficiently.',
  },
]

export {
	allCommentList
}