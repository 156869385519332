import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
import path from 'path'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Updated! GB WhatsApp v18.20 Was Released Nov 2024 (Anti-Ban)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GBWhatsApp released a new version, come to download GB WhatsApp APK on Android phone to solve the account ban issue. Full guide to use GB WhatsApp is also introduced in this post.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/new/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/downloadpage',
		name: 'en-download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp  Pro | GBWhatsApp Download | GB WhatsApp Update`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Here we support GB WhatsApp, GBWhatsApp Pro, WhatsApp Plus, YO WhatsApp, and FM WhatsApp Download and Update methods for Latest Version.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/downloadpage/'
				}
			]
		},
		component: () => import('@/views/download.vue'),
	},
    {
        path: '/es/downloadpage',
        name: 'es-download',
        meta: {
            appname: 'GBWhatsApp',
            title: `GBWhatsApp Pro | GBWhatsApp Descarga | Actualización de GB WhatsApp`,
            keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp descarga, gb whatsapp descarga',
            description: `Aquí apoyamos GB WhatsApp, GBWhatsApp Pro, WhatsApp Plus, YO WhatsApp y métodos de descarga y actualización de FM WhatsApp para la última versión.`,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://gbwhatapks.com/es/downloadpage/'
                }
            ]
        },
        component: () => import('@/views/es-download.vue'),
    },
    {
        path: '/ar/downloadpage',
        name: 'ar-download',
        meta: {
            appname: 'GBWhatsApp',
            title: `جي بي واتساب برو | تحميل جي بي واتساب | تحديث جي بي واتساب`,
            keywords: 'جي بي واتساب, تحميل جي بي واتساب, تحديث جي بي واتساب',
            description: `هنا ندعم جي بي واتساب، جي بي واتساب برو، واتساب بلس، يو واتساب، وطرق تحميل وتحديث جي بي واتساب لإصدار أحدث.`,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://gbwhatapks.com/ar/downloadpage/'
                }
            ]
        },
        component: () => import('@/views/ar-download.vue'),
    },
    {
        path: '/hi/downloadpage',
        name: 'hi-download',
        meta: {
            appname: 'GBWhatsApp',
            title: `GBWhatsApp प्रो | GBWhatsApp डाउनलोड | GB WhatsApp अपडेट`,
            keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
            description: `यहाँ हम GB WhatsApp, GBWhatsApp प्रो, WhatsApp प्लस, YO WhatsApp, और FM WhatsApp के लिए नवीनतम संस्करण के लिए डाउनलोड और अपडेट विधियों का समर्थन करते हैं।`,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://gbwhatapks.com/hi/downloadpage/'
                }
            ]
        },
        component: () => import('@/views/hi-download.vue'),
    },
    {
        path: '/id/downloadpage',
        name: 'id-download',
        meta: {
            appname: 'GBWhatsApp',
            title: `GBWhatsApp Pro | Unduh GBWhatsApp | Pembaruan GB WhatsApp`,
            keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
            description: `Di sini kami mendukung GB WhatsApp, GBWhatsApp Pro, WhatsApp Plus, YO WhatsApp, dan metode unduh dan pembaruan untuk versi terbaru.`,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://gbwhatapks.com/id/downloadpage/'
                }
            ]
        },
        component: () => import('@/views/id-download.vue'),
    },
    {
        path: '/pt/downloadpage',
        name: 'pt-download',
        meta: {
            appname: 'GBWhatsApp',
            title: `GBWhatsApp Pro | Download GBWhatsApp | Atualização do GB WhatsApp`,
            keywords: 'gbwhatsapp, gb whatsapp, download gbwhatsapp, download gb whatsapp',
            description: `Aqui apoiamos GB WhatsApp, GBWhatsApp Pro, WhatsApp Plus, YO WhatsApp e métodos de download e atualização do FM WhatsApp para a versão mais recente.`,
            link: [
                {
                    rel: 'canonical',
                    href: 'https://gbwhatapks.com/pt/downloadpage/'
                }
            ]
        },
        component: () => import('@/views/pt-download.vue'),
    },
	{
		path: '/blogs',
		name: 'en-blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: ` GBWhatsApp | Why do people prefer GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/'
				}
			]
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/hi/blogs',
		name: 'hi-blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | लोग GB WhatsApp को क्यों पसंद करते हैं?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `यदि आप GBWhatsapp की सुरक्षा या GBWhatsapp के प्रतिबंध मुद्दे या GBWhatsapp के बारे में किसी अन्य प्रश्न के बारे में चिंतित हैं, तो कृपया इसे पढ़ें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/'
				}
			]
		},
		component: () => import('@/views/hi-blogs.vue'),
	},
	{
		path: '/pt/blogs',
		name: 'pt-blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | Por que as pessoas preferem o GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, download gbwhatsapp, download gb whatsapp',
			description: `Se você está preocupado com a segurança do GBWhatsapp ou com a questão da proibição do GBWhatsapp ou qualquer outra pergunta sobre o GBWhatsapp, por favor, leia isso.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/'
				}
			]
		},
		component: () => import('@/views/pt-blogs.vue'),
	},	
	{
		path: '/id/blogs',
		name: 'id-blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp | Mengapa orang lebih memilih GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
			description: `Jika Anda khawatir tentang keamanan GBWhatsApp atau tentang larangan GBWhatsApp atau pertanyaan lain tentang GBWhatsApp, silakan baca ini.`,			
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/id/blogs/'
				}
			]
		},
		component: () => import('@/views/id-blogs.vue'),
	},
	{
		path: '/blogs/gbwhatsapp-multiple-devices-with-same-account',
		name: 'en-blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp on Multiple Devices with the Same Account`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `How to use the same WhatsApp account on different phones? Read this blog and follow the detailed steps.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/gbwhatsapp-multiple-devices-with-same-account'
				}
			]
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/blog-1',
		redirect: '/blogs/gbwhatsapp-multiple-devices-with-same-account',
	},
	{
		path: '/hi/blogs/gbwhatsapp-multiple-devices-with-same-account',
		name: 'hi-blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `एक ही अकाउंट के साथ कई डिवाइसों पर GB WhatsApp का उपयोग करें`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `कैसे एक ही WhatsApp अकाउंट को अलग-अलग फोन पर इस्तेमाल किया जाए? इस ब्लॉग को पढ़ें और विस्तृत चरणों का पालन करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/gbwhatsapp-multiple-devices-with-same-account'
				}
			]
		},
		component: () => import('@/views/hi-blog1.vue'),
	},
	{
		path: '/pt/blogs/gbwhatsapp-multiple-devices-with-same-account',
		name: 'pt-blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `Use o GB WhatsApp em vários dispositivos com a mesma conta`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Como usar a mesma conta do WhatsApp em diferentes telefones? Leia este blog e siga os passos detalhados.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/gbwhatsapp-multiple-devices-with-same-account'
				}
			]
		},
		component: () => import('@/views/pt-blog1.vue'),
	},	
	{
			path: '/id/blogs/gbwhatsapp-multiple-devices-with-same-account',
			name: 'id-blog1',
			meta: {
					appname: 'GBWhatsApp',
					title: `Gunakan GB WhatsApp di beberapa perangkat dengan akun yang sama`,
					keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
					description: `Bagaimana cara menggunakan akun WhatsApp yang sama di berbagai ponsel? Baca blog ini dan ikuti langkah-langkah yang terperinci.`,
					link: [
							{
									rel: 'canonical',
									href: 'https://gbwhatapks.com/id/blogs/gbwhatsapp-multiple-devices-with-same-account'
							}
					]
			},
			component: () => import('@/views/id-blog1.vue'),
	},
	{
		path: '/blogs/recover-gb-whatsapp-data',
		name: 'en-blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `Guide: How to Recover GB WhatsApp Data?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `In this blog, you can follow the guides to recover GB WhatsApp Data including chat history.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/recover-gb-whatsapp-data'
				}
			]
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/blog-2',
		redirect: '/blogs/recover-gb-whatsapp-data',
	},
	{
		path: '/hi/blogs/recover-gb-whatsapp-data',
		name: 'hi-blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `गाइड: GB WhatsApp डेटा कैसे पुनः प्राप्त करें?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `इस ब्लॉग में, आप GB WhatsApp डेटा को पुनः प्राप्त करने के निर्देशों का पालन कर सकते हैं जिसमें चैट इतिहास भी शामिल है।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/recover-gb-whatsapp-data'
				}
			]
		},
		component: () => import('@/views/hi-blog2.vue'),
	},	
	{
		path: '/pt/blogs/recover-gb-whatsapp-data',
		name: 'pt-blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `Guia: Como Recuperar os Dados do GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, baixar gbwhatsapp, baixar gb whatsapp',
			description: `Neste blog, você pode seguir os guias para recuperar os dados do GB WhatsApp, incluindo o histórico de conversas.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/recover-gb-whatsapp-data'
				}
			]
		},
		component: () => import('@/views/pt-blog2.vue'),
	},	
	{
    path: '/id/blogs/recover-gb-whatsapp-data',
    name: 'id-blog2',
    meta: {
        appname: 'GBWhatsApp',
        title: `Panduan: Cara Mengembalikan Data GB WhatsApp?`,
        keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
        description: `Dalam blog ini, Anda dapat mengikuti panduan untuk mengembalikan data GB WhatsApp, termasuk riwayat percakapan.`,
        link: [
            {
                rel: 'canonical',
                href: 'https://gbwhatapks.com/id/blogs/recover-gb-whatsapp-data'
            }
        ]
    },
    component: () => import('@/views/id-blog2.vue'),
	},	
	{
		path: '/blogs/create-gb-whatsapp-channels',
		name: 'en-blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to create GB WhatsApp Channels?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp new version added a new feature to help users create a channel. Read this blog and follow the guide.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/GBWhatsApp-Channels/'
				}
			]
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/blogs/GBWhatsApp-Channels',
		redirect: '/blogs/create-gb-whatsapp-channels',
	},
	{
		path: '/hi/blogs/create-gb-whatsapp-channels',
		name: 'hi-blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp चैनल कैसे बनाएं?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `GB WhatsApp के नए संस्करण में उपयोगकर्ताओं को चैनल बनाने में मदद करने के लिए एक नया फीचर जोड़ा गया है। इस ब्लॉग को पढ़ें और गाइड का पालन करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/GBWhatsApp-Channels/'
				}
			]
		},
		component: () => import('@/views/hi-blog3.vue'),
	},
	{
		path: '/pt/blogs/create-gb-whatsapp-channels',
		name: 'pt-blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `Como criar canais do GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `A nova versão do GB WhatsApp adicionou um novo recurso para ajudar os usuários a criar um canal. Leia este blog e siga o guia.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/GBWhatsApp-Channels/'
				}
			]
		},
		component: () => import('@/views/pt-blog3.vue'),
	},
	{
    path: '/id/blogs/create-gb-whatsapp-channels',
    name: 'id-blog3',
    meta: {
        appname: 'GBWhatsApp',
        title: `Cara Membuat Saluran di GB WhatsApp?`,
        keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
        description: `Versi baru dari GB WhatsApp menambahkan fitur baru untuk membantu pengguna membuat saluran. Baca blog ini dan ikuti panduannya.`,
        link: [
            {
                rel: 'canonical',
                href: 'https://gbwhatapks.com/id/blogs/GBWhatsApp-Channels/'
            }
        ]
    },
    component: () => import('@/views/id-blog3.vue'),
	},
	{
		path: '/blogs/commom-questions-about-gb-whatsapp',
		name: 'en-blog4',
		meta: {
			appname: 'GBWhatsApp',
			title: `A Collection of Common Questions about GB WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp is a modified version of WhatsApp. New users may have many questions about this app. Read the questions and answers we collected here.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/commom-questions-about-gb-whatsapp'
				}
			]
		},
		component: () => import('@/views/blog4.vue'),
	},
	{
		path: '/blog-4',
		redirect: '/blogs/commom-questions-about-gb-whatsapp',
	},
	{
		path: '/hi/blogs/commom-questions-about-gb-whatsapp',
		name: 'hi-blog4',
		meta: {
		  appname: 'GBWhatsApp',
		  title: `जीबी व्हाट्सएप के बारे में सामान्य प्रश्नों का संग्रह`,
		  keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
		  description: `जीबी व्हाट्सएप व्हाट्सएप का एक संशोधित संस्करण है। नए उपयोगकर्ताओं को इस ऐप के बारे में कई प्रश्न हो सकते हैं। यहाँ हमने एकत्रित प्रश्न और उत्तर पढ़ें।`,
		  link: [
			{
			  rel: 'canonical',
			  href: 'https://gbwhatapks.com/hi/blogs/commom-questions-about-gb-whatsapp'
			}
		  ]
		},
		component: () => import('@/views/hi-blog4.vue'),
	},	  
	{
		path: '/pt/blogs/commom-questions-about-gb-whatsapp',
		name: 'pt-blog4',
		meta: {
		  appname: 'GBWhatsApp',
		  title: `Uma Coleção de Perguntas Comuns sobre GB WhatsApp`,
		  keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
		  description: `O GB WhatsApp é uma versão modificada do WhatsApp. Novos usuários podem ter muitas perguntas sobre este aplicativo. Leia as perguntas e respostas que coletamos aqui.`,
		  link: [
			{
			  rel: 'canonical',
			  href: 'https://gbwhatapks.com/pt/blogs/commom-questions-about-gb-whatsapp'
			}
		  ]
		},
		component: () => import('@/views/pt-blog4.vue'),
	},	  
	{
    path: '/id/blogs/pertanyaan-umum-tentang-gb-whatsapp',
    name: 'id-blog4',
    meta: {
        appname: 'GBWhatsApp',
        title: `Kumpulan Pertanyaan Umum tentang GB WhatsApp`,
        keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
        description: `GB WhatsApp adalah versi modifikasi dari WhatsApp. Pengguna baru mungkin memiliki banyak pertanyaan tentang aplikasi ini. Baca pertanyaan dan jawaban yang kami kumpulkan di sini.`,
        link: [
            {
                rel: 'canonical',
                href: 'https://gbwhatapks.com/id/blogs/pertanyaan-umum-tentang-gb-whatsapp'
            }
        ]
    },
    component: () => import('@/views/id-blog4.vue'),
	},
	{
		path: '/blogs/10-apps-to-spy-whatsapp-activity',
		name: 'en-blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `The 10 Apps to Spy WhatsApp Activity 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Is it possible to monitor someone's WhatsApp activity? We tested and ranked the top 10 apps designed for tracking WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/10-apps-to-spy-whatsapp-activity/'
				}
			]
		},
		component: () => import('@/views/blog5.vue'),
	},
	{
		path: '/blog-5',
		redirect: '/blogs/10-apps-to-spy-whatsapp-activity',
	},
	{
		path: '/hi/blogs/10-apps-to-spy-whatsapp-activity',
		name: 'hi-blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `व्हाट्सएप गतिविधियों की जासूसी करने के लिए 10 ऐप्स 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `क्या किसी के व्हाट्सएप गतिविधियों की निगरानी करना संभव है? हमने व्हाट्सएप को ट्रैक करने के लिए डिज़ाइन किए गए शीर्ष 10 ऐप्स का परीक्षण किया और रैंक किया।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/10-apps-to-spy-whatsapp-activity/'
				}
			]
		},
		component: () => import('@/views/hi-blog5.vue'),
	},
	{
		path: '/pt/blogs/10-apps-to-spy-whatsapp-activity',
		name: 'pt-blog5',
		meta: {
			appname: 'GBWhatsApp',
			title: `Os 10 Aplicativos para Monitorar Atividades no WhatsApp 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `É possível monitorar a atividade de alguém no WhatsApp? Testamos e classificamos os 10 melhores aplicativos projetados para rastrear o WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/10-apps-to-spy-whatsapp-activity/'
				}
			]
		},
		component: () => import('@/views/pt-blog5.vue'),
	},	
	{
    path: '/id/blogs/10-aplikasi-untuk-memonitor-aktivitas-whatsapp',
    name: 'id-blog5',
    meta: {
        appname: 'GBWhatsApp',
        title: `10 Aplikasi untuk Memantau Aktivitas di WhatsApp 2025`,
        keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
        description: `Apakah mungkin untuk memantau aktivitas seseorang di WhatsApp? Kami menguji dan mengurutkan 10 aplikasi terbaik yang dirancang untuk melacak WhatsApp.`,
        link: [
            {
                rel: 'canonical',
                href: 'https://gbwhatapks.com/id/blogs/10-aplikasi-untuk-memonitor-aktivitas-whatsapp'
            }
        ]
    },
    component: () => import('@/views/id-blog5.vue'),
	},
	{
		path: '/blogs/top-10-whatsapp-mods-for-android',
		name: 'en-blog6',
		meta: {
			appname: 'GBWhatsApp',
			title: `Top 10 WhatsApp Mods For Android 2024 (Anti-Ban)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Download popular WhatsApp Mods for Android with anti-ban protection enhanced. All the top WhatsApp Mods provide extra custom features for you.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/top-10-whatsapp-mods-for-android'
				}
			]
		},
		component: () => import('@/views/blog6.vue'),
	},
	{
		path: '/blog-6',
		redirect: '/blogs/top-10-whatsapp-mods-for-android',
	},
	{
		path: '/hi/blogs/top-10-whatsapp-mods-for-android',
		name: 'hi-blog6',
		meta: {
			appname: 'GBWhatsApp',
			title: `एंड्रॉइड के लिए शीर्ष 10 व्हाट्सएप मोड 2024 (एंटी-बैन)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `एंटी-बैन सुरक्षा के साथ लोकप्रिय व्हाट्सएप मोड डाउनलोड करें। सभी शीर्ष व्हाट्सएप मोड अतिरिक्त कस्टम सुविधाएँ प्रदान करते हैं।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/top-10-whatsapp-mods-for-android'
				}
			]
		},
		component: () => import('@/views/hi-blog6.vue'),
	},
	{
		path: '/pt/blogs/top-10-whatsapp-mods-for-android',
		name: 'pt-blog6',
		meta: {
			appname: 'GBWhatsApp',
			title: `Os 10 Melhores Mods do WhatsApp para Android 2024 (Anti-Ban)`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Baixe os mods populares do WhatsApp para Android com proteção anti-ban aprimorada. Todos os principais mods do WhatsApp oferecem recursos extras personalizados para você.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/top-10-whatsapp-mods-for-android'
				}
			]
		},
		component: () => import('@/views/pt-blog6.vue'),
	},
	{
    path: '/id/blogs/10-mod-whatsapp-terbaik-untuk-android',
    name: 'id-blog6',
    meta: {
        appname: 'GBWhatsApp',
        title: `10 Mod WhatsApp Terbaik untuk Android 2024 (Anti-Ban)`,
        keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
        description: `Unduh mod populer WhatsApp untuk Android dengan perlindungan anti-ban yang ditingkatkan. Semua mod utama WhatsApp menawarkan fitur tambahan yang disesuaikan untuk Anda.`,
        link: [
            {
                rel: 'canonical',
                href: 'https://gbwhatapks.com/id/blogs/10-mod-whatsapp-terbaik-untuk-android'
            }
        ]
    },
    component: () => import('@/views/id-blog6.vue'),
	},
	{
		path: '/blogs/is-gbwhatsappo-over',
		name: 'en-blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Discussion: Is GBWhatsApp Over?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Recent issues have raised concerns about GBWhatsApp future. Will all GBWhatsApp accounts being banned one day? Read the article to learn more.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/blogs/is-gbwhatsappo-over'
				}
			]
		},
		component: () => import('@/views/blog7.vue'),
	},
	{
		path: '/hi/blogs/is-gbwhatsappo-over',
		name: 'hi-blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp चर्चा: क्या GBWhatsApp समाप्त हो गया है?`,
			keywords: 'gbwhatsapp, gb व्हाट्सएप, gbwhatsapp डाउनलोड, gb व्हाट्सएप डाउनलोड',
			description: `हाल की समस्याओं ने GBWhatsApp के भविष्य पर चिंता जताई है। क्या एक दिन सभी GBWhatsApp खातों पर प्रतिबंध लगाया जाएगा? अधिक जानने के लिए लेख पढ़ें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/blogs/is-gbwhatsappo-over'
				}
			]
		},
		component: () => import('@/views/hi-blog7.vue'),
	},
	{
		path: '/pt/blogs/is-gbwhatsappo-over',
		name: 'pt-blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `Discussão do GBWhatsApp: O GBWhatsApp está acabando?`,
			keywords: 'gbwhatsapp, gb whatsapp, download gbwhatsapp, download gb whatsapp',
			description: `Questões recentes levantaram preocupações sobre o futuro do GBWhatsApp. Será que um dia todas as contas do GBWhatsApp serão banidas? Leia o artigo para saber mais.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/blogs/is-gbwhatsappo-over'
				}
			]
		},
		component: () => import('@/views/pt-blog7.vue'),
	},	
	{
		path: '/id/blogs/is-gbwhatsappo-over',
		name: 'id-blog7',
		meta: {
			appname: 'GBWhatsApp',
			title: `Diskusi GBWhatsApp: Apakah GBWhatsApp Berakhir?`,
			keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
			description: `Masalah terbaru menimbulkan kekhawatiran tentang masa depan GBWhatsApp. Apakah semua akun GBWhatsApp akan diblokir suatu hari nanti? Baca artikel untuk mempelajari lebih lanjut.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/id/blogs/is-gbwhatsappo-over'
				}
			]
		},
		component: () => import('@/views/id-blog7.vue'),
	},	
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/privacy/'
				}
			]
		},
		component: () => import('@/views/privacy.vue'),
	},
	{
		path: '/ar',
		name: 'ar-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK تنزيل الإصدار v18.20 2024 لشهر يونيو`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `واتساب الذهبي هو تطبيق رائع لأي شخص يريد تجربة واتساب أكثر تخصيصًا وغنية بالميزات. قم بتنزيل واتساب الذهبي للحصول على الإصدار الثابت.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/ar/'
				}
			]
		},
		component: () => import('@/views/ar.vue'),
	},
	{
		path: '/id',
		name: 'id-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `WhatsApp GB|Unduh GB WhatsApp Pro APK Versi v18.20`,
			description: `GBWhatsApp Pro adalah aplikasi yang hebat untuk siapa saja yang menginginkan pengalaman WhatsApp yang lebih personal dan kaya fitur. Unduh WhatsApp GB untuk versi yang sudah diperbaiki.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/id/'
				}
			]
		},
		component: () => import('@/views/id.vue'),
	},
	{
		path: '/pt',
		name: 'pt-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `WhatsApp GB| Baixar WhatsApp GB Pro Versão v18.20`,
			description: `WhatsApp GB Pro é um ótimo aplicativo para quem deseja uma experiência do WhatsApp mais personalizada e rica em recursos. Baixe o WhatsApp GB para a versão corrigida.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/'
				}
			]
		},
		component: () => import('@/views/pt.vue'),
	},
	{
		path: '/es',
		name: 'es-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK İndir v18.20 2024 Haziran Sürümü`,
			description: `GBWhatsApp Pro, daha kişiselleştirilmiş ve özellik açısından zengin bir WhatsApp deneyimi isteyen herkes için harika bir uygulamadır. Düzeltme sürümü için GBWhatsApp'ı indirin.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/es/'
				}
			]
		},
		component: () => import('@/views/es.vue'),
	},
	{
		path: '/hi',
		name: 'hi-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK डाउनलोड v18.20 2024 जून संस्करण`,
			description: `GBWhatsApp Pro उन सभी के लिए एक शानदार ऐप है जो अधिक व्यक्तिगत और सुविधाओं से भरपूर WhatsApp अनुभव चाहते हैं। फिक्स्ड संस्करण के लिए GBWhatsApp डाउनलोड करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/'
				}
			]
		},
		component: () => import('@/views/hi.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
	{
		path: '/whatsapp-mods',
		name: 'en-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Mods | GBWhatsApp Download | GB WhatsApp Update`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GBWhatsApp Mods are the modified versions of WhatsApp that provide more features and customization options. Download the latest GBWhatsApp Mods here.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/mod.vue'),
	},
	{
		path: '/ar/whatsapp-mods',
		name: 'ar-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `تعديلات GBWhatsApp | تحميل GBWhatsApp | تحديث GB WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, تحميل gbwhatsapp, تحميل gb whatsapp',
			description: `تعديلات GBWhatsApp هي النسخ المعدلة من WhatsApp التي توفر المزيد من الميزات وخيارات التخصيص. قم بتنزيل أحدث تعديلات GBWhatsApp هنا.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/ar/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/ar-mod.vue'),
	},
	{
		path: '/es/whatsapp-mods',
		name: 'es-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Modificaciones de GBWhatsApp | Descargar GBWhatsApp | Actualización de GB WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, descargar gbwhatsapp, descargar gb whatsapp',
			description: `Las modificaciones de GBWhatsApp son versiones modificadas de WhatsApp que ofrecen más características y opciones de personalización. Descarga las últimas modificaciones de GBWhatsApp aquí.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/es/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/es-mod.vue'),
	},
	{
		path: '/hi/whatsapp-mods',
		name: 'hi-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Mods | GBWhatsApp डाउनलोड | GB WhatsApp अपडेट`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp डाउनलोड, gb whatsapp डाउनलोड',
			description: `GBWhatsApp Mods व्हाट्सएप के संशोधित संस्करण हैं जो अधिक सुविधाएँ और अनुकूलन विकल्प प्रदान करते हैं। यहां नवीनतम GBWhatsApp Mods डाउनलोड करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/hi/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/hi-mod.vue'),
	},	
	{
		path: '/id/whatsapp-mods',
		name: 'id-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Modifikasi GBWhatsApp | Unduh GBWhatsApp | Pembaruan GB WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, unduh gbwhatsapp, unduh gb whatsapp',
			description: `Modifikasi GBWhatsApp adalah versi modifikasi dari WhatsApp yang menawarkan lebih banyak fitur dan opsi kustomisasi. Unduh modifikasi GBWhatsApp terbaru di sini.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/id/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/id-mod.vue'),
	},
	{
		path: '/pt/whatsapp-mods',
		name: 'pt-mod',
		meta: {
			appname: 'GBWhatsApp',
			title: `Mods do GBWhatsApp | Baixar GBWhatsApp | Atualização do GB WhatsApp`,
			keywords: 'gbwhatsapp, gb whatsapp, baixar gbwhatsapp, baixar gb whatsapp',
			description: `Os Mods do GBWhatsApp são versões modificadas do WhatsApp que oferecem mais recursos e opções de personalização. Baixe os mods mais recentes do GBWhatsApp aqui.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/pt/whatsapp-mods/'
				}
			]
		},
		component: () => import('@/views/pt-mod.vue'),
	},	
	{
		path: '/fm-whatsapp',
		name: 'en-fm',
		meta: {
			appname: 'GBWhatsApp',
			title: `FM WhatsApp Official: New Version Released 2024`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, fm whatsapp, fm whatsapp download',
			description: `FM WhatsApp has just launched its latest version for 2024, bringing exciting updates, enhanced security features, and bug fixes.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/fm-whatsapp/'
				}
			]
		},
		component: () => import('@/views/fm.vue'),
	},
	{
		path: '/whatsapp-plus',
		name: 'en-plus',
		meta: {
			appname: 'GBWhatsApp',
			title: `WhatsApp Plus APK v18.20 Download for Free`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, whatsapp plus, whatsapp plug download',
			description: `On this website you can get more detailed information about WhatsApp Plus. It's a modified version of the official WhatsApp which has one more billions of users.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/whatsapp-plus/'
				}
			]
		},
		component: () => import('@/views/plus.vue'),
	},
	{
		path: '/yowhatsapp-download',
		name: 'en-yo',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download YoWhatsApp APK v10.10 Latest Version`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, whatsapp plus, whatsapp plug download',
			description: `YoWhatsapp Anti-Ban protection enhanced version was released for Android users. Explore the attractive features in this site.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/yowhatsapp-download/'
				}
			]
		},
		component: () => import('@/views/yo.vue'),
	},
	{
		path: '/gbwhatsapp-ios',
		name: 'en-ios',
		meta: {
			appname: 'GBWhatsApp',
			title: `Download GBWhatsApp iOS Latest Version 2024`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, whatsapp plus, whatsapp plug download',
			description: `GBWhatsApp iOS provides an alternative version for the official WhatsApp. It also provides users with more custom features. Enter the site to learn more.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/gbwhatsapp-ios/'
				}
			]
		},
		component: () => import('@/views/ios.vue'),
	},
	{
		path: '/ob-whatsapp',
		name: 'en-ob',
		meta: {
			appname: 'GBWhatsApp',
			title: `OBWhatsApp | Download OB WhatsApp APK v62 APK`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, whatsapp plus, whatsapp plug download',
			description: `Download OBWhatsApp latest version and experience the custom features developed for users. This app is a modified version but with enhanced anti-ban protection.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/ob-whatsapp/'
				}
			]
		},
		component: () => import('@/views/ob.vue'),
	},
	{
		path: '/gbwhatsapp-pro',
		name: 'en-pro',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Pro v18.20 APK Updated Nov 2024`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download, whatsapp plus, whatsapp plug download',
			description: `A trusted site to download latest version of GB WhatsApp Pro for free. GBWhatsApp Pro provides more custom features than the official WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbwhatapks.com/gbwhatsapp-pro/'
				}
			]
		},
		component: () => import('@/views/pro.vue'),
	},

]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.png'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.png'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.png'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.png'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
